define("ember-svg-jar/inlined/dyno-2x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>dyno-2x</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M8.39.19l5.72 2.952c.487.251.89.907.89 1.459v6.866c0 .548-.398 1.203-.89 1.455l-5.72 2.93c-.487.25-1.288.253-1.78 0l-5.72-2.93C.403 12.673 0 12.02 0 11.467V4.6c0-.548.398-1.205.89-1.459L6.61.189c.487-.25 1.288-.253 1.78 0z\" fill=\"#F1EEF5\"/><path d=\"M8.39.19l5.72 2.952c.487.251.89.907.89 1.459v6.866c0 .548-.398 1.203-.89 1.455l-5.72 2.93c-.487.25-1.288.253-1.78 0l-5.72-2.93C.403 12.673 0 12.02 0 11.467V4.6c0-.548.398-1.205.89-1.459L6.61.189c.487-.25 1.288-.253 1.78 0zm-.459.888c-.202-.104-.661-.104-.862 0L1.349 4.03C1.19 4.112 1 4.426 1 4.6v6.866c0 .174.191.486.346.565l5.72 2.93c.203.105.666.104.868 0l5.72-2.93c.156-.08.346-.39.346-.565V4.6c0-.175-.192-.49-.348-.57l-5.72-2.953z\" fill=\"#C1B1D3\"/><path d=\"M2.14 11h4.576V9.775H4.064c.032-.271.617-.645 1.386-1.13 1.138-.717 1.226-1.298 1.226-1.894C6.676 5.668 5.915 5 4.568 5c-1.13 0-1.843.525-2.396 1.281l1.114.812c.345-.478.842-.772 1.234-.772.377 0 .601.199.601.541 0 .573-.36.724-1.298 1.321-1.563.995-1.683 1.838-1.683 2.69V11zm5.13 0h1.69l.986-1.798L10.996 11h1.788l-1.86-3.08 1.651-2.809h-1.643l-.817 1.552-.89-1.552H7.398l1.707 2.794L7.269 11z\" fill=\"#A185C0\"/></g>",
    "attrs": {
      "width": "15",
      "height": "17",
      "viewBox": "0 0 15 17",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
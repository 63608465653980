define("@heroku/ember-hk-components/components/hk-slide-panel/header", ["exports", "@ember/component", "@heroku/ember-hk-components/templates/components/hk-slide-panel/header", "@ember/object/computed"], function (_exports, _component, _header, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _header.default,
    'data-test-target': 'slide-panel-header',
    classNames: ['hk-slide-panel-header', 'relative', 'flex', 'justify-center', 'tc', 'items-center', 'relative', 'bb', 'b--light-silver', 'bg-white', 'z-2'],
    defaultDismissLabel: 'Dismiss slide panel',
    shownDismissLabel: (0, _computed.or)('dismissLabel', 'defaultDismissLabel'),
    actions: {
      dismiss() {
        this.get('onDismiss')();
      }

    }
  });

  _exports.default = _default;
});
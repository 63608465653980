define("ember-svg-jar/inlined/dyno-px", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>dyno-px</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M8.39.19l5.72 2.952c.487.251.89.907.89 1.459v6.866c0 .548-.398 1.203-.89 1.455l-5.72 2.93c-.487.25-1.288.253-1.78 0l-5.72-2.93C.403 12.673 0 12.02 0 11.467V4.6c0-.548.398-1.205.89-1.459L6.61.189c.487-.25 1.288-.253 1.78 0z\" fill=\"#F1EEF5\"/><path d=\"M8.39.19l5.72 2.952c.487.251.89.907.89 1.459v6.866c0 .548-.398 1.203-.89 1.455l-5.72 2.93c-.487.25-1.288.253-1.78 0l-5.72-2.93C.403 12.673 0 12.02 0 11.467V4.6c0-.548.398-1.205.89-1.459L6.61.189c.487-.25 1.288-.253 1.78 0zm-.459.888c-.202-.104-.661-.104-.862 0L1.349 4.03C1.19 4.112 1 4.426 1 4.6v6.866c0 .174.191.486.346.565l5.72 2.93c.203.105.666.104.868 0l5.72-2.93c.156-.08.346-.39.346-.565V4.6c0-.175-.192-.49-.348-.57l-5.72-2.953z\" fill=\"#C1B1D3\"/><path d=\"M2.6 11h1.584V9.086h.632c1.16 0 2.56-.267 2.56-2.043C7.376 5.268 6.08 5 4.816 5H2.6v6zm1.584-3.146v-1.54h.688c.744 0 .944.316.944.77s-.2.77-.944.77h-.688zM7.6 11h1.688l.984-1.832L11.32 11h1.784l-1.856-3.138L12.896 5h-1.64l-.816 1.581L9.552 5H7.728l1.704 2.846L7.6 11z\" fill=\"#A185C0\"/></g>",
    "attrs": {
      "width": "15",
      "height": "17",
      "viewBox": "0 0 15 17",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
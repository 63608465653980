define("@heroku/ember-hk-components/templates/components/hk-slide-panel/confirmable-action", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "TWELV/FZ",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[14,\"data-test-target\",\"slide-panel-confirmable-action-content\"],[12],[1,\"\\n\"],[6,[39,2],null,[[\"onClickOverlay\"],[[28,[37,3],[[30,0],[33,4]],null]]],[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"br1 br--top\"],[12],[1,\"\\n      \"],[10,2],[14,0,\"ma0 pv3 ph4 f5 lh-copy\"],[12],[1,\"\\n        \"],[18,2,null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"flex pa4 pt0\"],[12],[1,\"\\n        \"],[1,[28,[35,6],null,[[\"onClick\",\"buttonType\",\"class\",\"default\",\"data-test-target\"],[[28,[37,3],[[30,0],[33,7],[30,1,[\"dismiss\"]]],null],\"secondary\",\"w-50 mr2\",[33,8],\"confirmable-action-cancel\"]]]],[1,\"\\n\\n        \"],[1,[28,[35,6],null,[[\"onClick\",\"buttonType\",\"class\",\"default\",\"disabled\",\"data-test-target\"],[[28,[37,3],[[30,0],[33,9],[30,1,[\"dismiss\"]]],null],[33,10],\"w-50\",[33,11],[33,12],\"confirmable-action-confirm\"]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]]]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"breakout\",\"&default\"],false,[\"if\",\"isShowingConfirm\",\"hk-slide-panel/breakout\",\"action\",\"onClickOverlay\",\"yield\",\"hk-button\",\"onCancel\",\"secondaryButtonText\",\"onConfirm\",\"primaryButtonType\",\"primaryButtonText\",\"primaryDisabled\"]]",
    "moduleName": "@heroku/ember-hk-components/templates/components/hk-slide-panel/confirmable-action.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("@heroku/ember-hk-components/templates/components/hk-form-group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "61sseTtw",
    "block": "[[[18,1,[[28,[37,1],null,[[\"value\",\"label\",\"field\",\"errorsList\"],[[33,2],[50,\"hk-label\",0,null,[[\"for\",\"isRequired\",\"data-test-target\"],[[33,4],[33,5],[28,[37,6],[\"form-group-label-\",[33,7]],null]]]],[50,[33,8],0,null,[[\"id\",\"value\",\"isValid\",\"isInvalid\",\"isValidating\",\"aria-describedby\",\"data-test-target\"],[[33,4],[33,2],[33,9],[33,10],[33,11],[28,[37,6],[[33,4],\"-errors-list\"],null],[28,[37,6],[\"form-group-field-\",[33,7]],null]]]],[50,\"hk-validation-errors-list\",0,null,[[\"validationErrors\",\"property\",\"errorsListId\"],[[33,12],[33,7],[33,13]]]]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"value\",\"component\",\"groupId\",\"isRequired\",\"concat\",\"property\",\"fieldType\",\"isValid\",\"isInvalid\",\"isValidating\",\"validationErrors\",\"errorsListId\"]]",
    "moduleName": "@heroku/ember-hk-components/templates/components/hk-form-group.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define('ember-data-github/models/github-pull', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    number: (0, _attr.default)('number'),
    title: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    htmlUrl: (0, _attr.default)('string'),
    body: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    closedAt: (0, _attr.default)('date'),
    mergedAt: (0, _attr.default)('date'),
    userLogin: (0, _attr.default)('string'),
    userAvatarUrl: (0, _attr.default)('string'),

    user: (0, _relationships.belongsTo)('github-user', { inverse: null })
  });
});
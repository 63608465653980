define("ember-sortable/utils/css-calculation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getBorderSpacing = getBorderSpacing;

  /**
    Gets a numeric border-spacing values for a given element.
  
    @method getBorderSpacing
    @param {Element} element
    @return {Object}
    @private
  */
  function getBorderSpacing(el) {
    let css = getComputedStyle(el).borderSpacing; // '0px 0px'

    let [horizontal, vertical] = css.split(' ');
    return {
      horizontal: parseFloat(horizontal),
      vertical: parseFloat(vertical)
    };
  }
});
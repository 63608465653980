define('ember-changeset-validations/helpers/changeset', ['exports', 'ember-changeset', 'ember-changeset-validations', 'ember-changeset/utils/is-object', 'ember-changeset/utils/is-promise'], function (exports, _emberChangeset, _emberChangesetValidations, _isObject, _isPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.changeset = changeset;
  function changeset([model, validationMap], options = {}) {
    if ((0, _isObject.default)(validationMap)) {
      if ((0, _isPromise.default)(model)) {
        return model.then(resolved => new _emberChangeset.default(resolved, (0, _emberChangesetValidations.default)(validationMap), validationMap, options));
      }

      return new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(validationMap), validationMap, options);
    }

    if ((0, _isPromise.default)(model)) {
      return model.then(resolved => new _emberChangeset.default(resolved, validationMap, {}, options));
    }

    return new _emberChangeset.default(model, validationMap, {}, options);
  }

  exports.default = Ember.Helper.helper(changeset);
});
define('ember-data-github/serializers/github-branch', ['exports', 'ember-data-github/serializers/github'], function (exports, _github) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _github.default.extend({
    normalize(modelClass, resourceHash, prop) {
      resourceHash.id = resourceHash.recordId || resourceHash.commit.url.replace('https://api.github.com/repos/', '').replace(/\/commits\/.+/, `/branches/${resourceHash.name}`);

      return this._super(modelClass, resourceHash, prop);
    }
  });
});
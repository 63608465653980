define("@adopted-ember-addons/ember-stripe-elements/components/stripe-element", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service"], function (_exports, _component, _tracking, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let StripeElement = (_class = class StripeElement extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "stripeElement", _descriptor, this);

      _initializerDefineProperty(this, "type", _descriptor2, this);

      _initializerDefineProperty(this, "_stripeError", _descriptor3, this);

      _initializerDefineProperty(this, "stripev3", _descriptor4, this);
    }

    get autofocus() {
      return this.args.autofocus;
    }

    get options() {
      return this.args.options || {};
    }

    get elements() {
      if (this.args._elements) {
        return this.args._elements;
      }

      return this.stripev3.elements();
    }

    get stripeError() {
      return this.args.stripeError || this._stripeError;
    }

    set stripeError(error) {
      this._stripeError = error;
    }

    registerListeners(element) {
      this.mountElement(element);
      this.setEventListeners();
      this.focusElement(element);
    }

    mountElement(element) {
      // Fetch user options
      let options = this.args.options; // `stripeElement` instead of `element` to distinguish from `element`

      let stripeElement = this.elements.create(this.type, options); // Mount the Stripe Element onto the mount point

      stripeElement.mount(element); // Make the element available to the component

      this.stripeElement = stripeElement;
      this.stripev3.addStripeElement(stripeElement);
    }

    focusElement(element) {
      // Fetch autofocus, set by user
      let iframe = element.querySelector('iframe');

      if (this.autofocus && iframe) {
        iframe.onload = () => {
          this.stripeElement.focus();
        };
      }
    }

    setEventListeners() {
      var _this = this;

      let {
        stripeElement
      } = this;
      stripeElement.on('ready', event => {
        this._invokeAction('onReady', stripeElement, event);
      });
      stripeElement.on('blur', event => {
        this._invokeAction('onBlur', stripeElement, event);
      });
      stripeElement.on('focus', event => {
        this._invokeAction('onFocus', stripeElement, event);
      });
      stripeElement.on('change', function () {
        if (_this.isDestroying || _this.isDestroyed) {
          return;
        }

        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        let [{
          complete,
          error: stripeError
        }] = args;
        _this.args.onChange?.(stripeElement, ...args);

        if (complete) {
          _this._invokeAction('onComplete', stripeElement);
        } else if (stripeError) {
          _this._invokeAction('onError', stripeError);
        }

        _this.stripeError = stripeError;
      });
    }

    _invokeAction(method) {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      if (typeof this.args[method] === 'function') {
        for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
          args[_key2 - 1] = arguments[_key2];
        }

        this.args[method](...args);
      }
    }

    onOptionsChange() {
      let options = this.options;
      this.stripeElement.update(options);
    }

    willDestroy() {
      this.stripeElement.unmount();
      this.stripev3.removeStripeElement(this.stripeElement);
      super.willDestroy(...arguments);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "stripeElement", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "type", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_stripeError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stripev3", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "registerListeners", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerListeners"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onOptionsChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onOptionsChange"), _class.prototype)), _class);
  _exports.default = StripeElement;
});
define("ember-svg-jar/inlined/dyno-ps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>PX Copy</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M8.39.19c-.492-.254-1.293-.252-1.78 0L.89 3.141C.398 3.396 0 4.053 0 4.601v6.866c0 .552.403 1.206.89 1.455l5.72 2.93c.492.253 1.293.25 1.78 0l5.72-2.93c.492-.252.89-.907.89-1.455V4.6c0-.552-.403-1.208-.89-1.459L8.39.189z\" fill=\"#C5AEDF\"/><path d=\"M2.7 11h.992V8.752H4.74c1.216 0 2.312-.424 2.312-1.856 0-1.368-1-1.816-2.28-1.816H2.7V11zm.992-3.12V5.992H4.78c.888 0 1.288.28 1.288.936 0 .68-.4.952-1.28.952H3.692zm6.584 3.232c1.408 0 2.232-.76 2.232-1.728 0-1.112-.896-1.48-2.016-1.808C9.524 7.288 9.1 7.104 9.1 6.552c0-.432.376-.672 1.032-.672.64 0 1.144.272 1.496.864l.728-.504c-.36-.696-1.048-1.272-2.216-1.272-1.2 0-2.024.664-2.024 1.672 0 1.128.92 1.44 2.064 1.776.976.288 1.344.528 1.344 1.04 0 .536-.488.768-1.208.768-.736 0-1.28-.336-1.632-1.032l-.792.416c.376.896 1.208 1.504 2.384 1.504z\" fill=\"#79589F\"/></g>",
    "attrs": {
      "width": "15",
      "height": "16",
      "viewBox": "0 0 15 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define('ember-data-github/adapters/github-repository-contents', ['exports', 'ember-data-github/adapters/github'], function (exports, _github) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _github.default.extend({
    urlForQueryRecord(query) {
      const repo = query.repo;
      const file = query.file;
      delete query.repo;
      delete query.file;

      return `${this.get('host')}/repos/${repo}/contents/${file}`;
    }
  });
});
define('ember-data-github/models/github-tree', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    sha: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    files: (0, _attr.default)(), // object
    directories: (0, _attr.default)(), // object
    truncated: (0, _attr.default)('boolean'),

    blobs: (0, _relationships.hasMany)('github-blob'),
    trees: (0, _relationships.hasMany)('github-tree')
  });
});
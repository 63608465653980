define("ember-sortable/utils/defaults", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.defaultA11yAnnouncementConfig = void 0;
  const defaultA11yAnnouncementConfig = {
    ACTIVATE(_ref) {
      let {
        a11yItemName,
        index,
        maxLength,
        direction
      } = _ref;
      let message = `${a11yItemName} at position, ${index + 1} of ${maxLength}, is activated to be repositioned.`;

      if (direction === 'y') {
        message += 'Press up and down keys to change position,';
      } else {
        message += 'Press left and right keys to change position,';
      }

      message += ' Space to confirm new position, Escape to cancel.';
      return message;
    },

    MOVE(_ref2) {
      let {
        a11yItemName,
        index,
        maxLength,
        delta
      } = _ref2;
      return `${a11yItemName} is moved to position, ${index + 1 + delta} of ${maxLength}. Press Space to confirm new position, Escape to cancel.`;
    },

    CONFIRM(_ref3) {
      let {
        a11yItemName
      } = _ref3;
      return `${a11yItemName} is successfully repositioned.`;
    },

    CANCEL(_ref4) {
      let {
        a11yItemName
      } = _ref4;
      return `Cancelling ${a11yItemName} repositioning`;
    }

  };
  _exports.defaultA11yAnnouncementConfig = defaultA11yAnnouncementConfig;
});
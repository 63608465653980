define('ember-changeset/-private/relay', ['exports', 'ember-changeset/utils/is-relay'], function (exports, _isRelay) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.ObjectProxy.extend({
    /*::
    _super() {},
    notifyPropertyChange() {},
    _changedKeys: {},
    */

    /**
     * Internal descriptor for relay identification.
     */
    __relay__: _isRelay.RELAY,

    changeset: null,
    key: '',
    content: null,

    init() {
      let r /*: RelayDef */ = this;
      r._super(...arguments);
      r._changedKeys = {};
      (false && !(!!Ember.get(this, 'changeset')) && Ember.assert('changeset must be present.', !!Ember.get(this, 'changeset')));
      (false && !(!!Ember.get(this, 'content')) && Ember.assert('content must be present.', !!Ember.get(this, 'content')));
      (false && !(!!Ember.get(this, 'key')) && Ember.assert('key must be present.', !!Ember.get(this, 'key')));
    },

    unknownProperty(key) {
      let r /*: RelayDef */ = this;
      if (!r.changeset) throw new Error('Relay has no changeset.');
      return r.changeset._valueFor(`${r.key}.${key}`);
    },

    setUnknownProperty(key, value) {
      let r /*: RelayDef */ = this;
      r._changedKeys[key] = null;
      if (!r.changeset) throw new Error('Relay has no changeset.');
      r.changeset._validateAndSet(`${r.key}.${key}`, value);
      r.notifyPropertyChange(key);
      return value;
    },

    rollback() {
      let r /*: RelayDef */ = this;
      r._super(...arguments);
      for (let k of Object.keys(r._changedKeys)) r.notifyPropertyChange(k);
      r._changedKeys = {};
    },

    destroy() {
      let r /*: RelayDef */ = this;
      r._super(...arguments);
      r.changeset = null;
      r.content = null;
    },

    isEqual(other) {
      let r /*: RelayDef */ = this;
      let original = Ember.get(r, 'content');
      return r === other || original === other;
    }
  } /*: RelayDef */);
});
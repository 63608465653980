define('ember-data-github/adapters/github-repository', ['exports', 'ember-data-github/adapters/github'], function (exports, _github) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _github.default.extend({
    urlForFindRecord(id /*, modelName, snapshot*/) {
      const isInteger = /^\d+$/;
      let builtURL = this._super(...arguments);
      if (!isInteger.test(id)) {
        builtURL = builtURL.replace('repositories', 'repos');
      }
      return builtURL.replace('%2F', '/');
    },

    urlForQuery(query /*, modelName */) {
      let builtURL = this._super(...arguments);
      let { user } = query;

      delete query.user;

      return builtURL.replace('repositories', `users/${user}/repos`);
    }
  });
});
define("ember-cookies/services/cookies", ["exports", "@ember/object", "@ember/object/computed", "@ember/utils", "@ember/debug", "@ember/array", "@ember/application", "@ember/service", "@ember/polyfills"], function (_exports, _object, _computed, _utils, _debug, _array, _application, _service, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    keys
  } = Object;
  const assign = Object.assign || _polyfills.assign || _polyfills.merge;
  const DEFAULTS = {
    raw: false
  };
  const MAX_COOKIE_BYTE_LENGTH = 4096;

  var _default = _service.default.extend({
    _isFastBoot: (0, _computed.reads)('_fastBoot.isFastBoot'),
    _fastBoot: (0, _object.computed)(function () {
      let owner = (0, _application.getOwner)(this);
      return owner.lookup('service:fastboot');
    }),
    _document: (0, _object.computed)(function () {
      return document;
    }),

    _getDocumentCookies() {
      let all = this.get('_document.cookie').split(';');

      let filtered = this._filterDocumentCookies((0, _array.A)(all));

      return filtered.reduce((acc, cookie) => {
        if (!(0, _utils.isEmpty)(cookie)) {
          let [key, value] = cookie;
          acc[key.trim()] = (value || '').trim();
        }

        return acc;
      }, {});
    },

    _getFastBootCookies() {
      let fastBootCookies = this.get('_fastBoot.request.cookies');
      fastBootCookies = (0, _array.A)(keys(fastBootCookies)).reduce((acc, name) => {
        let value = fastBootCookies[name];
        acc[name] = {
          value
        };
        return acc;
      }, {});
      let fastBootCookiesCache = this._fastBootCookiesCache || {};
      fastBootCookies = assign({}, fastBootCookies, fastBootCookiesCache);
      this._fastBootCookiesCache = fastBootCookies;
      return this._filterCachedFastBootCookies(fastBootCookies);
    },

    read(name) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      options = assign({}, DEFAULTS, options || {});
      (false && !((0, _utils.isEmpty)(options.domain) && (0, _utils.isEmpty)(options.expires) && (0, _utils.isEmpty)(options.maxAge) && (0, _utils.isEmpty)(options.path)) && (0, _debug.assert)('Domain, Expires, Max-Age, and Path options cannot be set when reading cookies', (0, _utils.isEmpty)(options.domain) && (0, _utils.isEmpty)(options.expires) && (0, _utils.isEmpty)(options.maxAge) && (0, _utils.isEmpty)(options.path)));
      let all;

      if (this.get('_isFastBoot')) {
        all = this._getFastBootCookies();
      } else {
        all = this._getDocumentCookies();
      }

      if (name) {
        return this._decodeValue(all[name], options.raw);
      } else {
        (0, _array.A)(keys(all)).forEach(name => all[name] = this._decodeValue(all[name], options.raw));
        return all;
      }
    },

    write(name, value) {
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      options = assign({}, DEFAULTS, options || {});
      (false && !(!options.signed) && (0, _debug.assert)("Cookies cannot be set as signed as signed cookies would not be modifyable in the browser as it has no knowledge of the express server's signing key!", !options.signed));
      (false && !((0, _utils.isEmpty)(options.expires) || (0, _utils.isEmpty)(options.maxAge)) && (0, _debug.assert)('Cookies cannot be set with both maxAge and an explicit expiration time!', (0, _utils.isEmpty)(options.expires) || (0, _utils.isEmpty)(options.maxAge)));
      value = this._encodeValue(value, options.raw);
      (false && !(this._isCookieSizeAcceptable(value)) && (0, _debug.assert)(`Cookies larger than ${MAX_COOKIE_BYTE_LENGTH} bytes are not supported by most browsers!`, this._isCookieSizeAcceptable(value)));

      if (this.get('_isFastBoot')) {
        this._writeFastBootCookie(name, value, options);
      } else {
        (false && !(!options.httpOnly) && (0, _debug.assert)('Cookies cannot be set to be HTTP-only from a browser!', !options.httpOnly));

        this._writeDocumentCookie(name, value, options);
      }
    },

    clear(name) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      options = assign({}, options || {});
      (false && !((0, _utils.isEmpty)(options.expires) && (0, _utils.isEmpty)(options.maxAge) && (0, _utils.isEmpty)(options.raw)) && (0, _debug.assert)('Expires, Max-Age, and raw options cannot be set when clearing cookies', (0, _utils.isEmpty)(options.expires) && (0, _utils.isEmpty)(options.maxAge) && (0, _utils.isEmpty)(options.raw)));
      options.expires = new Date('1970-01-01');
      this.write(name, null, options);
    },

    exists(name) {
      let all;

      if (this.get('_isFastBoot')) {
        all = this._getFastBootCookies();
      } else {
        all = this._getDocumentCookies();
      }

      return all.hasOwnProperty(name);
    },

    _writeDocumentCookie(name, value) {
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      let serializedCookie = this._serializeCookie(name, value, options);

      this.set('_document.cookie', serializedCookie);
    },

    _writeFastBootCookie(name, value) {
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      let responseHeaders = this.get('_fastBoot.response.headers');

      let serializedCookie = this._serializeCookie(...arguments);

      if (!(0, _utils.isEmpty)(options.maxAge)) {
        options.maxAge *= 1000;
      }

      this._cacheFastBootCookie(...arguments);

      let replaced = false;
      let existing = responseHeaders.getAll('set-cookie');

      for (let i = 0; i < existing.length; i++) {
        if (existing[i].startsWith(`${name}=`)) {
          existing[i] = serializedCookie;
          replaced = true;
          break;
        }
      }

      if (!replaced) {
        responseHeaders.append('set-cookie', serializedCookie);
      }
    },

    _cacheFastBootCookie(name, value) {
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      let fastBootCache = this._fastBootCookiesCache || {};
      let cachedOptions = assign({}, options);

      if (cachedOptions.maxAge) {
        let expires = new Date();
        expires.setSeconds(expires.getSeconds() + options.maxAge);
        cachedOptions.expires = expires;
        delete cachedOptions.maxAge;
      }

      fastBootCache[name] = {
        value,
        options: cachedOptions
      };
      this._fastBootCookiesCache = fastBootCache;
    },

    _filterCachedFastBootCookies(fastBootCookies) {
      let {
        path: requestPath,
        protocol
      } = this.get('_fastBoot.request'); // cannot use deconstruct here

      let host = this.get('_fastBoot.request.host');
      return (0, _array.A)(keys(fastBootCookies)).reduce((acc, name) => {
        let {
          value,
          options
        } = fastBootCookies[name];
        options = options || {};
        let {
          path: optionsPath,
          domain,
          expires,
          secure
        } = options;

        if (optionsPath && requestPath.indexOf(optionsPath) !== 0) {
          return acc;
        }

        if (domain && host.indexOf(domain) + domain.length !== host.length) {
          return acc;
        }

        if (expires && expires < new Date()) {
          return acc;
        }

        if (secure && !(protocol || '').match(/^https/)) {
          return acc;
        }

        acc[name] = value;
        return acc;
      }, {});
    },

    _encodeValue(value, raw) {
      if ((0, _utils.isNone)(value)) {
        return '';
      } else if (raw) {
        return value;
      } else {
        return encodeURIComponent(value);
      }
    },

    _decodeValue(value, raw) {
      if ((0, _utils.isNone)(value) || raw) {
        return value;
      } else {
        return decodeURIComponent(value);
      }
    },

    _filterDocumentCookies(unfilteredCookies) {
      return unfilteredCookies.map(c => {
        let separatorIndex = c.indexOf('=');
        return [c.substring(0, separatorIndex), c.substring(separatorIndex + 1)];
      }).filter(c => c.length === 2 && (0, _utils.isPresent)(c[0]));
    },

    _serializeCookie(name, value) {
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      let cookie = `${name}=${value}`;

      if (!(0, _utils.isEmpty)(options.domain)) {
        cookie = `${cookie}; domain=${options.domain}`;
      }

      if ((0, _utils.typeOf)(options.expires) === 'date') {
        cookie = `${cookie}; expires=${options.expires.toUTCString()}`;
      }

      if (!(0, _utils.isEmpty)(options.maxAge)) {
        cookie = `${cookie}; max-age=${options.maxAge}`;
      }

      if (options.secure) {
        cookie = `${cookie}; secure`;
      }

      if (options.httpOnly) {
        cookie = `${cookie}; httpOnly`;
      }

      if (!(0, _utils.isEmpty)(options.path)) {
        cookie = `${cookie}; path=${options.path}`;
      }

      return cookie;
    },

    _isCookieSizeAcceptable(value) {
      // Counting bytes varies Pre-ES6 and in ES6
      // This snippet counts the bytes in the value
      // about to be stored as the cookie:
      // See https://stackoverflow.com/a/25994411/6657064
      let _byteCount = 0;
      let i = 0;
      let c;

      while (c = value.charCodeAt(i++)) {
        /* eslint-disable no-bitwise */
        _byteCount += c >> 11 ? 3 : c >> 7 ? 2 : 1;
        /* eslint-enable no-bitwise */
      }

      return _byteCount < MAX_COOKIE_BYTE_LENGTH;
    }

  });

  _exports.default = _default;
});
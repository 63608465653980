define('ember-data-github/serializers/github-organization', ['exports', 'ember-data-github/serializers/github'], function (exports, _github) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _github.default.extend({
    normalize(modelClass, resourceHash, prop) {
      resourceHash.id = resourceHash.recordId || resourceHash.login;
      resourceHash.links = {
        members: resourceHash.members_url.replace(/\{\/member\}/, ''),
        repositories: resourceHash.repos_url
      };
      return this._super(modelClass, resourceHash, prop);
    }
  });
});
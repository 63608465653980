define("ember-cli-flash/templates/components/flash-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1j7EuMK1",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[30,0],[33,3],[28,[37,4],[[30,0],\"close\"],null]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[33,3,[\"message\"]]],[1,\"\\n\"],[41,[33,5],[[[1,\"    \"],[10,0],[14,0,\"alert-progress\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"alert-progressBar\"],[15,5,[36,6]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"flash\",\"action\",\"showProgressBar\",\"progressDuration\"]]",
    "moduleName": "ember-cli-flash/templates/components/flash-message.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define('ember-changeset/utils/is-relay', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isRelay;
  const RELAY = exports.RELAY = '__RELAY__';

  function isRelay(relay /*: mixed */) /*: boolean */{
    if (!relay) return false;
    return Ember.get(relay, '__relay__') === RELAY;
  }
});
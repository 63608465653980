define("ember-svg-jar/inlined/hex-tap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><linearGradient id=\"bJbxKya\" x1=\"50%\" x2=\"50%\" y1=\"0%\" y2=\"100%\"><stop offset=\"0%\" stop-color=\"#FCFAFF\"/><stop offset=\"100%\" stop-color=\"#F2E7FF\"/></linearGradient><linearGradient id=\"bJbxKyb\" x1=\"0%\" y1=\"0%\" y2=\"100%\"><stop offset=\"0%\" stop-color=\"#AB8ECD\"/><stop offset=\"100%\" stop-color=\"#79589F\"/></linearGradient></defs><g fill=\"none\" fill-rule=\"nonzero\"><path fill=\"url(#bJbxKya)\" d=\"M15.927 1.793a6.356 6.356 0 016.148 0L32.92 7.825C34.826 8.886 36 10.845 36 12.968v12.064c0 2.122-1.175 4.083-3.08 5.144l-10.845 6.03a6.345 6.345 0 01-6.148 0l-10.846-6.03C3.175 29.116 2 27.155 2 25.032V12.968c0-2.124 1.175-4.082 3.081-5.143l10.846-6.032z\"/><path fill=\"#CDBFDB\" d=\"M5.49 9.148c-1.343.743-2.168 2.109-2.168 3.587v12.53c0 1.477.825 2.845 2.168 3.587l11.336 6.263a4.517 4.517 0 004.35 0l11.336-6.262c1.341-.743 2.166-2.11 2.166-3.588v-12.53c0-1.478-.824-2.844-2.166-3.587L21.177 2.883a4.527 4.527 0 00-4.352 0L5.49 9.148zm10.437-7.355a6.356 6.356 0 016.148 0L32.92 7.825C34.826 8.886 36 10.845 36 12.968v12.064c0 2.122-1.175 4.083-3.08 5.144l-10.845 6.03a6.345 6.345 0 01-6.148 0l-10.846-6.03C3.175 29.116 2 27.155 2 25.032V12.968c0-2.124 1.175-4.082 3.081-5.143l10.846-6.032z\"/><path fill=\"url(#bJbxKyb)\" d=\"M20 13.571v1.286h.491c.487 0 .932.275 1.15.71l.93 1.862h2.572c.71 0 1.286.575 1.286 1.285V20c0 .71-.576 1.286-1.286 1.286H14.857c0 .71-.575 1.285-1.286 1.285h-1.285c-.71 0-1.286-.575-1.286-1.285V20a2.571 2.571 0 012.571-2.571h2.572l.93-1.861c.218-.436.663-.71 1.15-.71h.491V13.57l-.025-.012a2.804 2.804 0 00-1.762-.254 1.286 1.286 0 11.012-2.028A2.74 2.74 0 0018.714 11H20a2.74 2.74 0 001.775.276 1.286 1.286 0 11.012 2.028 2.804 2.804 0 00-1.761.255L20 13.57zm-6.973 10.395c1.225 1.392 1.835 2.432 1.83 3.12a1.929 1.929 0 01-3.857 0c-.005-.688.607-1.728 1.834-3.12a.128.128 0 01.193 0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "38",
      "height": "38",
      "viewBox": "0 0 38 38"
    }
  };
  _exports.default = _default;
});
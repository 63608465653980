define("ember-modal-dialog/components/liquid-tether-dialog", ["exports", "@ember-decorators/component", "@ember/object", "@ember/string", "ember-modal-dialog/components/basic-dialog", "ember-modal-dialog/templates/components/liquid-tether-dialog"], function (_exports, _component, _object, _string, _basicDialog, _liquidTetherDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let LiquidTetherDialog = (_dec = (0, _component.layout)(_liquidTetherDialog.default), _dec2 = (0, _object.computed)('targetAttachment'), _dec(_class = (_class2 = class LiquidTetherDialog extends _basicDialog.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "targetAttachment", null);

      _defineProperty(this, "attachment", null);

      _defineProperty(this, "hasOverlay", true);

      _defineProperty(this, "tetherTarget", null);
    }

    get targetAttachmentClass() {
      let targetAttachment = this.targetAttachment || ''; // Convert tether-styled values like 'middle right' to 'right'

      targetAttachment = targetAttachment.split(' ').slice(-1)[0];
      return `ember-modal-dialog-target-attachment-${(0, _string.dasherize)(targetAttachment)} emd-target-attachment-${(0, _string.dasherize)(targetAttachment)}`;
    }

    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);

      if (!this.attachment) {
        (0, _object.set)(this, 'attachment', 'middle center');
      }

      if (!this.targetAttachment) {
        (0, _object.set)(this, 'targetAttachment', 'middle center');
      }
    }

    get tetherClassPrefix() {
      return 'liquid-tether';
    }

    set tetherClassPrefix(val) {
      if (val) {
        return val;
      }

      return 'liquid-tether';
    } // element, css selector, view instance, 'viewport', or 'scroll-handle'
    // offset - passed in
    // targetOffset - passed in
    // targetModifier - passed in


  }, (_applyDecoratedDescriptor(_class2.prototype, "targetAttachmentClass", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "targetAttachmentClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "tetherClassPrefix", [_object.computed], Object.getOwnPropertyDescriptor(_class2.prototype, "tetherClassPrefix"), _class2.prototype)), _class2)) || _class);
  _exports.default = LiquidTetherDialog;
});
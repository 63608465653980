define('ember-data-github/adapters/github-tree', ['exports', 'ember-data-github/adapters/github'], function (exports, _github) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _github.default.extend({
    urlForQueryRecord(query) {
      const repo = query.repo;
      const sha = query.sha;
      delete query.repo;
      delete query.sha;

      if (query.recursive) {
        query.recursive = 1;
      }

      return `${this.get('host')}/repos/${repo}/git/trees/${sha}`;
    }
  });
});
define('ember-changeset-validations/validators/format', ['exports', 'ember-changeset-validations/utils/validation-errors', 'ember-validators'], function (exports, _validationErrors, _emberValidators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateInclusion;
  function validateInclusion(options = {}) {
    let hasOptions = !Ember.isEmpty(Object.keys(options));

    return (key, value) => {
      if (!hasOptions) {
        return true;
      }

      let result = (0, _emberValidators.validate)('format', value, options, null, key);
      return result === true ? true : (0, _validationErrors.default)(key, result);
    };
  }
});
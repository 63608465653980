define('ember-changeset/utils/computed/inflate', ['exports', 'ember-deep-set', 'ember-changeset/utils/is-object'], function (exports, _emberDeepSet, _isObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = inflate /*:: <T> */;


  const { keys } = Object;

  /**
   * Inflate an Object, optionally transforming each key's value by
   * `transform` function.
   */
  function inflate(dependentKey /*: string       */
  , transform /*: (T) => mixed */ = a => a) /*: Object */{
    return Ember.computed(dependentKey, function () {
      let obj /*: Object */ = Ember.get(this, dependentKey);

      Ember.runInDebug(() => {
        keys(obj).forEach(key => {
          key.split('.').forEach((_part, i, allParts) => {
            if (i < allParts.length - 1) {
              let path = allParts.slice(0, i + 1).join('.');
              let msg = `Path ${path} leading up to ${key} must be an Object if specified.`;
              (false && !((0, _isObject.default)(obj[path]) || Ember.isBlank(obj[path])) && Ember.assert(msg, (0, _isObject.default)(obj[path]) || Ember.isBlank(obj[path])));
            }
          });
        });
      });

      let result = keys(obj).sort().reduce((inflatedObj, key) => {
        (0, _emberDeepSet.default)(inflatedObj, key, transform(obj[key]));
        return inflatedObj;
      }, {});

      return result;
    }).readOnly();
  }
});
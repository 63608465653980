define("ember-svg-jar/inlined/ssl-dns-active", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><linearGradient id=\"huVZyRa\" x1=\"0%\" y1=\"0%\" y2=\"100%\"><stop offset=\"0%\" stop-color=\"#A1CAF9\"/><stop offset=\"100%\" stop-color=\"#408FEC\"/></linearGradient></defs><g fill=\"url(#huVZyRa)\" fill-rule=\"nonzero\"><path d=\"M23 32h2v6h-2v-6zm-2.485 8a3.627 3.627 0 000 2H7v-2h13.515zm6.97 0H41v2H27.485a3.627 3.627 0 000-2z\"/><path d=\"M24 44a3 3 0 100-6 3 3 0 000 6zm0 2a5 5 0 110-10 5 5 0 010 10zm5.596-26.599c.261-1.333.404-2.783.404-4.3 0-1.516-.143-2.965-.404-4.299H25v8.599h4.596zm-.505 2H25v5.434c1.724-.573 3.2-2.605 4.091-5.434zm-10.687-2H23v-8.599h-4.596a22.507 22.507 0 00-.404 4.3c0 1.516.143 2.966.404 4.299zm.505 2c.892 2.829 2.367 4.86 4.091 5.434v-5.434h-4.091zm16.189-2c.517-1.333.8-2.783.8-4.3a11.87 11.87 0 00-.8-4.299h-3.463a24.9 24.9 0 01.365 4.3 24.9 24.9 0 01-.365 4.299h3.463zm-1.002 2h-2.912c-.468 1.685-1.127 3.179-1.946 4.387 2-.982 3.684-2.509 4.858-4.387zm-21.194-2h3.463a24.9 24.9 0 01-.365-4.3c0-1.492.126-2.939.365-4.299h-3.463a11.87 11.87 0 00-.8 4.3c0 1.516.283 2.966.8 4.299zm1.002 2a11.952 11.952 0 004.858 4.387c-.819-1.208-1.478-2.702-1.946-4.387h-2.912zM29.091 8.802c-.892-2.829-2.367-4.86-4.091-5.434v5.434h4.091zm-10.182 0H23V3.368c-1.724.573-3.2 2.605-4.091 5.434zm15.187 0a11.952 11.952 0 00-4.858-4.387c.819 1.208 1.478 2.703 1.946 4.387h2.912zm-20.192 0h2.912c.468-1.684 1.127-3.179 1.946-4.387-2 .982-3.684 2.509-4.858 4.387zM24 29c-7.676 0-13.898-6.223-13.898-13.898 0-7.676 6.222-13.899 13.898-13.899 7.676 0 13.898 6.223 13.898 13.899C37.898 22.777 31.676 29 24 29z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48"
    }
  };
  _exports.default = _default;
});
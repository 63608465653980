define("ember-svg-jar/inlined/dyno-space-pl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>dyno-space-pl</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#E0E8F3\" d=\"M8.39.19l5.72 2.952c.487.251.89.907.89 1.459v6.866c0 .548-.398 1.203-.89 1.455l-5.72 2.93c-.487.25-1.288.253-1.78 0l-5.72-2.93C.403 12.673 0 12.02 0 11.467V4.6c0-.548.398-1.205.89-1.459L6.61.189c.487-.25 1.288-.253 1.78 0z\"/><path fill=\"#A5C6ED\" fill-rule=\"nonzero\" d=\"M8.39.19l5.72 2.952c.487.251.89.907.89 1.459v6.866c0 .548-.398 1.203-.89 1.455l-5.72 2.93c-.487.25-1.288.253-1.78 0l-5.72-2.93C.403 12.673 0 12.02 0 11.467V4.6c0-.548.398-1.205.89-1.459L6.61.189c.487-.25 1.288-.253 1.78 0zm-.459.888c-.202-.104-.661-.104-.862 0L1.349 4.03C1.19 4.112 1 4.426 1 4.6v6.866c0 .174.191.486.346.565l5.72 2.93c.203.105.666.104.868 0l5.72-2.93c.156-.08.346-.39.346-.565V4.6c0-.175-.192-.49-.348-.57l-5.72-2.953z\"/><path fill=\"#77ACEB\" d=\"M2.8 11h1.584V9.086h.632c1.16 0 2.56-.267 2.56-2.043C7.576 5.268 6.28 5 5.016 5H2.8v6zm1.584-3.146v-1.54h.688c.744 0 .944.316.944.77s-.2.77-.944.77h-.688zM8.3 11h4.1V9.695H9.905V5H8.3v6z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "17",
      "viewBox": "0 0 15 17"
    }
  };
  _exports.default = _default;
});
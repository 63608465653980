define('ember-data-github/models/github-blob', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    sha: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    content: (0, _attr.default)('string'),
    encoding: (0, _attr.default)('string'),
    size: (0, _attr.default)('number')
  });
});
define('ember-data-github/models/github-release', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    htmlUrl: (0, _attr.default)('string'),
    assetsUrl: (0, _attr.default)('string'),
    uploadUrl: (0, _attr.default)('string'),
    tarballUrl: (0, _attr.default)('string'),
    zipballUrl: (0, _attr.default)('string'),
    tagName: (0, _attr.default)('string'),
    targetCommitish: (0, _attr.default)('string'),
    body: (0, _attr.default)('string'),
    draft: (0, _attr.default)('boolean'),
    prerelease: (0, _attr.default)('boolean'),
    createdAt: (0, _attr.default)('date'),
    publishedAt: (0, _attr.default)('date'),

    author: (0, _relationships.belongsTo)('github-user', { inverse: null }),
    user: Ember.computed('author', function () {
      Ember.deprecate('The user property on the github-release model has been deprecated.  Please use the author property.', false, { id: 'ember-data-github.deprecated-model-props', until: '1.0.0' });
      return this.get('author');
    }),
    repository: (0, _relationships.belongsTo)('github-repository')
  });
});
define("@heroku/ember-hk-components/components/hk-autofocus-input", ["exports", "@ember/runloop", "@ember/component/text-field"], function (_exports, _runloop, _textField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _textField.default.extend({
    didInsertElement() {
      this._super(...arguments);

      (0, _runloop.scheduleOnce)('afterRender', this, this._focusInput);
    },

    _focusInput() {
      const el = this.element;

      if (el) {
        el.focus();
      }
    }

  });

  _exports.default = _default;
});
define('ember-data-github/models/github-compare', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    aheadBy: (0, _attr.default)('number'),
    behindBy: (0, _attr.default)('number'),
    status: (0, _attr.default)('string'),
    totalCommits: (0, _attr.default)('number'),
    diffUrl: (0, _attr.default)('string'),
    htmlUrl: (0, _attr.default)('string'),
    patchUrl: (0, _attr.default)('string'),
    permalinkUrl: (0, _attr.default)('string'),

    baseCommit: (0, _relationships.belongsTo)('github-commit'),
    mergeBaseCommit: (0, _relationships.belongsTo)('github-commit'),
    commits: (0, _relationships.hasMany)('github-commit'),
    files: (0, _relationships.hasMany)('github-file')
  });
});
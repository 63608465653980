define("@heroku/ember-hk-components/templates/components/hk-inline-edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "VYWgEmOJ",
    "block": "[[[10,\"form\"],[14,\"role\",\"form\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"hk-label db mb2\"],[15,\"for\",[36,0]],[12],[1,[34,1]],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-column flex-row-ns items-center-ns w-100\"],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"type\",\"value\",\"disabled\",\"maxlength\",\"placeholder\",\"id\",\"autocomplete\",\"data-test-target\",\"class\",\"key-up\"],[\"text\",[33,3],[33,4],[33,5],[33,6],[33,0],\"off\",\"hk-inline-value\",\"mr2 mb2 w-100 mw6\",[28,[37,7],[[30,0],\"valueChanged\",[33,8]],null]]]]],[1,\"\\n    \"],[10,0],[15,0,[29,[\"flex items-center mb2 \",[52,[51,[33,8]],\"clip\"]]]],[14,\"data-test-target\",\"hk-inline-buttons\"],[12],[1,\"\\n      \"],[1,[28,[35,10],null,[[\"onClick\",\"type\",\"default\",\"disableWhen\",\"buttonType\",\"data-test-target\",\"class\"],[[28,[37,7],[[30,0],\"save\",[33,3]],null],\"submit\",\"Save\",[33,11],\"primary\",\"hk-inline-submit\",\"mr1\"]]]],[1,\"\\n      \"],[1,[28,[35,10],null,[[\"onClick\",\"type\",\"default\",\"buttonType\",\"data-test-target\"],[[28,[37,7],[[30,0],\"cancelEdit\"],null],\"cancel\",\"Cancel\",\"secondary\",\"hk-inline-cancel\"]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"inputId\",\"labelWithAssert\",\"hk-input\",\"deferredValue\",\"isDisabled\",\"maxlength\",\"placeholder\",\"action\",\"valueHasChanged\",\"unless\",\"hk-button\",\"isUpdateDisabled\"]]",
    "moduleName": "@heroku/ember-hk-components/templates/components/hk-inline-edit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("@heroku/ember-hk-components/templates/components/hk-slide-panel/header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HxYVpdAj",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"ml4 flex-auto f3 dark-gray truncate lh-copy\"],[14,\"data-test-target\",\"slide-panel-header-title\"],[12],[1,\"\\n    \"],[1,[34,3]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"],[11,\"button\"],[24,0,\"flex bg-transparent bn mr2 mt0 pa1 pointer\"],[24,\"data-test-target\",\"slide-panel-dismiss\"],[16,\"aria-label\",[30,0,[\"shownDismissLabel\"]]],[24,4,\"button\"],[4,[38,4],[[30,0],\"dismiss\"],null],[12],[1,\"\\n  \"],[8,[39,5],null,[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"delete-16\",\"fill-gray\",16,[30,0,[\"shownDismissLabel\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"content\",\"action\",\"malibu-icon\"]]",
    "moduleName": "@heroku/ember-hk-components/templates/components/hk-slide-panel/header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("@heroku/ember-hk-components/components/hk-label", ["exports", "@ember/component", "@heroku/ember-hk-components/templates/components/hk-label"], function (_exports, _component, _hkLabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _hkLabel.default,
    attributeBindings: ['for'],
    classNames: ['hk-label'],
    for: null,
    tagName: 'label'
  });

  _exports.default = _default;
});
define("ember-svg-jar/inlined/region-ireland", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><linearGradient id=\"kvetLjb\" x1=\"50%\" x2=\"50%\" y1=\"0%\" y2=\"100%\"><stop offset=\"0%\" stop-color=\"#FFF\"/><stop offset=\"100%\" stop-color=\"#FAFBFD\"/></linearGradient><linearGradient id=\"kvetLjd\" x1=\"0%\" y1=\"0%\" y2=\"100%\"><stop offset=\"0%\" stop-color=\"#FFF\" stop-opacity=\".302\"/><stop offset=\"100%\" stop-color=\"#FFF\" stop-opacity=\"0\"/></linearGradient><rect id=\"kvetLja\" width=\"20\" height=\"14\" rx=\"2\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><mask id=\"kvetLjc\" fill=\"#fff\"><use xlink:href=\"#kvetLja\"/></mask><use fill=\"url(#kvetLjb)\" xlink:href=\"#kvetLja\"/><path fill=\"#169B62\" mask=\"url(#kvetLjc)\" d=\"M0 0h7v14H0z\"/><path fill=\"#FF883E\" mask=\"url(#kvetLjc)\" d=\"M13 0h7v14h-7z\"/><rect width=\"20\" height=\"14\" fill=\"url(#kvetLjd)\" rx=\"2\"/><path fill=\"#000\" fill-opacity=\".15\" fill-rule=\"nonzero\" d=\"M2.994.75A2.247 2.247 0 00.75 3.003v7.994a2.25 2.25 0 002.244 2.253h14.012a2.247 2.247 0 002.244-2.253V3.003A2.25 2.25 0 0017.006.75H2.994zm0-.75h14.012A3 3 0 0120 3.003v7.994A2.997 2.997 0 0117.006 14H2.994A3 3 0 010 10.997V3.003A2.997 2.997 0 012.994 0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "20",
      "height": "14",
      "viewBox": "0 0 20 14"
    }
  };
  _exports.default = _default;
});
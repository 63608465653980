define('ember-data-github/adapters/github-member', ['exports', 'ember-data-github/adapters/github'], function (exports, _github) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _github.default.extend({
    urlForQuery(query) {
      const org = query.org;
      delete query.org;

      return `${this.get('host')}/orgs/${org}/members`;
    }
  });
});
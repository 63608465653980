define("@heroku/ember-hk-components/components/hk-form-group", ["exports", "@heroku/ember-hk-components/components/hk-field-validations", "@heroku/ember-hk-components/templates/components/hk-form-group", "@ember/object"], function (_exports, _hkFieldValidations, _hkFormGroup, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hkFieldValidations.default.extend({
    layout: _hkFormGroup.default,
    fieldType: "hk-input",
    groupId: (0, _object.computed)(function () {
      return `hk-form-field-${this.get("elementId")}`;
    }),
    errorsListId: (0, _object.computed)("groupId", function () {
      return `${this.groupId}-errors-list`;
    }),
    isRequired: false
  });

  _exports.default = _default;
});
define('ember-changeset-validations/utils/validation-errors', ['exports', 'ember-changeset-validations/utils/get-messages', 'ember-get-config'], function (exports, _getMessages, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = buildMessage;


  const {
    assert,
    typeOf
  } = Ember;

  const assign = Ember.assign || Ember.merge;

  function buildMessage(key, result) {
    let returnsRaw = Ember.getWithDefault(_emberGetConfig.default, 'changeset-validations.rawOutput', false);
    let messages = (0, _getMessages.default)();

    let description = messages.getDescriptionFor(key);

    if (result.message) {
      return result.message;
    }

    let { type, value, context = {} } = result;

    if (context.message) {
      let message = context.message;

      if (typeOf(message) === 'function') {
        let builtMessage = message(key, type, value, context);
        assert('Custom message function must return a string', typeOf(builtMessage) === 'string');

        return builtMessage;
      }

      return messages.formatMessage(message, assign({ description }, context));
    }

    let message = Ember.get(messages, type);
    if (returnsRaw) {
      context = assign({}, context, { description });
      return { value, type, message, context };
    } else {
      return messages.formatMessage(message, assign({ description }, context));
    }
  }
});
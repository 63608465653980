define('ember-changeset-validations/index', ['exports', 'ember-changeset-validations/utils/wrap', 'ember-changeset-validations/utils/handle-multiple-validations', 'ember-changeset/utils/is-promise'], function (exports, _wrap, _handleMultipleValidations, _isPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = lookupValidator;
  function lookupValidator(validationMap = {}) {
    return ({ key, newValue, oldValue, changes, content }) => {
      let validator = validationMap[key];

      if (Ember.isEmpty(validator)) {
        return true;
      }

      if (Ember.isArray(validator)) {
        return (0, _handleMultipleValidations.default)(validator, { key, newValue, oldValue, changes, content });
      }

      let validation = validator(key, newValue, oldValue, changes, content);

      return (0, _isPromise.default)(validation) ? validation.then(_wrap.default) : [validation];
    };
  }
});
define("ember-modal-dialog/templates/components/tether-dialog", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "l+ittYSA",
    "block": "[[[41,[30,0,[\"hasOverlay\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@to\"],[[30,0,[\"destinationElementId\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[15,0,[30,0,[\"overlayClassNamesString\"]]],[15,\"onclick\",[28,[37,2],[[30,0],[30,0,[\"onClickOverlay\"]]],null]],[14,\"tabindex\",\"-1\"],[14,\"data-emd-overlay\",\"\"],[12],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null],[8,[39,3],[[17,1]],[[\"@class\",\"@target\",\"@attachment\",\"@targetAttachment\",\"@targetModifier\",\"@classPrefix\",\"@offset\",\"@targetOffset\",\"@constraints\"],[[30,0,[\"containerClassNamesString\"]],[30,0,[\"tetherTarget\"]],[30,0,[\"attachment\"]],[30,0,[\"targetAttachment\"]],[30,0,[\"targetModifier\"]],[30,0,[\"tetherClassPrefix\"]],[30,0,[\"offset\"]],[30,0,[\"targetOffset\"]],[30,0,[\"constraints\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[18,2,null],[1,\"\\n\"]],[]]]]]],[\"&attrs\",\"&default\"],false,[\"if\",\"ember-wormhole\",\"action\",\"ember-tether\",\"yield\"]]",
    "moduleName": "ember-modal-dialog/templates/components/tether-dialog.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
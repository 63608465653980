define("ember-svg-jar/inlined/region-europe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><linearGradient id=\"bTrewHb\" x1=\"0%\" y1=\"0%\" y2=\"100%\"><stop offset=\"0%\" stop-color=\"#64B5F3\"/><stop offset=\"100%\" stop-color=\"#347EE2\"/></linearGradient><rect id=\"bTrewHa\" width=\"20\" height=\"14\" rx=\"2\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><mask id=\"bTrewHc\" fill=\"#fff\"><use xlink:href=\"#bTrewHa\"/></mask><use fill=\"url(#bTrewHb)\" xlink:href=\"#bTrewHa\"/><path fill=\"#FC0\" d=\"M9.975 4.338l-.837.44.16-.933-.678-.66.936-.136.42-.849.418.849.937.136-.678.66.16.933-.838-.44zm0 6.84l-.837.44.16-.933-.678-.66.936-.136.42-.849.418.849.937.136-.678.66.16.933-.838-.44zm3.42-3.42l-.837.44.16-.933-.678-.66.936-.136.42-.849.418.849.937.136-.678.66.16.933-.838-.44zm-6.84 0l-.837.44.16-.933-.678-.66.936-.136.42-.849.418.849.937.136-.678.66.16.933-.838-.44zm.855-2.565l-.837.44.16-.933-.678-.66.936-.136.42-.849.418.849.937.136-.678.66.16.933-.838-.44zm0 5.13l-.837.44.16-.933-.678-.66.936-.136.42-.849.418.849.937.136-.678.66.16.933-.838-.44zm5.13-5.13l-.837.44.16-.933-.678-.66.936-.136.42-.849.418.849.937.136-.678.66.16.933-.838-.44zm0 5.13l-.837.44.16-.933-.678-.66.936-.136.42-.849.418.849.937.136-.678.66.16.933-.838-.44z\" mask=\"url(#bTrewHc)\"/><path fill=\"#000\" fill-opacity=\".15\" fill-rule=\"nonzero\" d=\"M2.994.75A2.247 2.247 0 00.75 3.003v7.994a2.25 2.25 0 002.244 2.253h14.012a2.247 2.247 0 002.244-2.253V3.003A2.25 2.25 0 0017.006.75H2.994zm0-.75h14.012A3 3 0 0120 3.003v7.994A2.997 2.997 0 0117.006 14H2.994A3 3 0 010 10.997V3.003A2.997 2.997 0 012.994 0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "20",
      "height": "14",
      "viewBox": "0 0 20 14"
    }
  };
  _exports.default = _default;
});
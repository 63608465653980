define("ember-svg-jar/inlined/dyno-space-pm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>dyno-space-pm</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#E0E8F3\" d=\"M8.39.19l5.72 2.952c.487.251.89.907.89 1.459v6.866c0 .548-.398 1.203-.89 1.455l-5.72 2.93c-.487.25-1.288.253-1.78 0l-5.72-2.93C.403 12.673 0 12.02 0 11.467V4.6c0-.548.398-1.205.89-1.459L6.61.189c.487-.25 1.288-.253 1.78 0z\"/><path fill=\"#A5C6ED\" fill-rule=\"nonzero\" d=\"M8.39.19l5.72 2.952c.487.251.89.907.89 1.459v6.866c0 .548-.398 1.203-.89 1.455l-5.72 2.93c-.487.25-1.288.253-1.78 0l-5.72-2.93C.403 12.673 0 12.02 0 11.467V4.6c0-.548.398-1.205.89-1.459L6.61.189c.487-.25 1.288-.253 1.78 0zm-.459.888c-.202-.104-.661-.104-.862 0L1.349 4.03C1.19 4.112 1 4.426 1 4.6v6.866c0 .174.191.486.346.565l5.72 2.93c.203.105.666.104.868 0l5.72-2.93c.156-.08.346-.39.346-.565V4.6c0-.175-.192-.49-.348-.57l-5.72-2.953z\"/><path fill=\"#77ACEB\" d=\"M1.8 11h1.539V9.086h.614c1.127 0 2.487-.267 2.487-2.043C6.44 5.268 5.18 5 3.953 5H1.8v6zm1.539-3.146v-1.54h.668c.723 0 .917.316.917.77s-.194.77-.917.77H3.34zM7 11h1.383V7.205H8.4c.124.787.194 1.054.326 1.54L9.316 11h1.368l.606-2.254c.125-.462.218-.843.311-1.46h.016V11H13.1V5h-2.106l-.91 4.07h-.015L9.06 5H7v6z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "17",
      "viewBox": "0 0 15 17"
    }
  };
  _exports.default = _default;
});
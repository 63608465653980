define("@heroku/ember-malibu-icon/helpers/malibu-icon", ["exports", "ember", "@ember/component/helper", "@ember/string", "@ember/object/internals", "@ember/debug"], function (_exports, _ember, _helper, _string, _internals, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.malibuIcon = malibuIcon;

  /**
   * Handlebars
     {{malibu-icon size='28' iconClass='gradient-dark-gray' name='app-28' title="Optional Title"}}
   *
   * Javascript
     import { malibuIcon } from '@heroku/ember-malibu-icon/helpers/malibu-icon';
     malibuIcon({
       name: 'app-16',
       size: 16,
       iconClass: 'v-mid gradient-purple',
       title='Optional Title'
     })
   **/
  const {
    escapeExpression: escape
  } = _ember.default.Handlebars.Utils;

  function malibuIcon(params, hash) {
    // if we didn't get a hash of params then use the object passed in
    // we expect the hash when used as a handlerbars component and the params
    // when used via javascript
    const parameters = hash === undefined ? params : hash;
    const {
      title,
      size,
      height,
      width,
      iconClass,
      name
    } = parameters;
    const iconWidth = width || size;
    const iconHeight = height || size;
    const labelID = (0, _internals.guidFor)({});
    const ariaHidden = parameters['aria-hidden'] || 'false';
    const role = parameters.role || 'img';
    (false && (0, _debug.warn)(`Please set a title for the ${name} icon ` + 'or set aria-hidden="true" if you believe the icon does not ' + 'need a title and is solely decorative. ' + 'Learn more about accessible icons here: ' + 'http://design.herokai.com/malibu', parameters.title !== undefined || ariaHidden === 'true', {
      id: "ember-malibu-icon.missing-title"
    }));
    return (0, _string.htmlSafe)(`
    <svg style="width: ${escape(iconWidth)}px; height: ${escape(iconHeight)}px;"
      data-test-target="malibu-icon" data-test-icon-name="${escape(name)}"
      class="icon malibu-icon ${escape(iconClass)}"
      aria-hidden="${escape(ariaHidden)}"
      aria-labelledby="${escape(title ? labelID : '')}"
      role="${escape(role)}"
    >
      <title id="${escape(title ? labelID : '')}">${escape(title)}</title>
      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#${escape(name)}" role="${escape(role)}">
      </use>
    </svg>
  `);
  }

  var _default = _helper.default.helper(malibuIcon);

  _exports.default = _default;
});
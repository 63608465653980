define("@heroku/ember-malibu-icon/-private/utils", ["exports", "@ember/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.requireNumeric = requireNumeric;

  function requireNumeric(string) {
    const parsed = parseInt(string, 10);
    (false && !(!Number.isNaN(parsed)) && (0, _debug.assert)('a numerical value must be passed to malibu-icon', !Number.isNaN(parsed)));
    return parsed;
  }
});
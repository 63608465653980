define('ember-data-github/serializers/github-repository', ['exports', 'ember-data-github/serializers/github', 'ember-data'], function (exports, _github, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { EmbeddedRecordsMixin } = _emberData.default;

  exports.default = _github.default.extend(EmbeddedRecordsMixin, {
    attrs: {
      owner: { embedded: 'always' }
    },

    normalize(modelClass, resourceHash, prop) {
      resourceHash.id = resourceHash.recordId || resourceHash.full_name;
      resourceHash.links = {
        defaultBranch: `${resourceHash.url}/branches/${resourceHash.default_branch}`,
        branches: `${resourceHash.url}/branches`,
        pulls: `${resourceHash.url}/pulls`,
        releases: `${resourceHash.url}/releases`
      };
      return this._super(modelClass, resourceHash, prop);
    }
  });
});
define("ember-svg-jar/inlined/hex-ready", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><linearGradient id=\"exyroFa\" x1=\"50%\" x2=\"50%\" y1=\"0%\" y2=\"100%\"><stop offset=\"0%\" stop-color=\"#FCFAFF\"/><stop offset=\"100%\" stop-color=\"#F2E7FF\"/></linearGradient><linearGradient id=\"exyroFb\" x1=\"0%\" y1=\"0%\" y2=\"100%\"><stop offset=\"0%\" stop-color=\"#AB8ECD\"/><stop offset=\"100%\" stop-color=\"#79589F\"/></linearGradient><path id=\"exyroFc\" d=\"M19 11a8 8 0 110 16 8 8 0 010-16zm-1.486 9.269l-1.32-1.32a.802.802 0 00-1.137-.006.802.802 0 00.006 1.137l1.823 1.823a.811.811 0 00.61.239.791.791 0 00.637-.23l4.128-4.127a.799.799 0 00-.004-1.128.795.795 0 00-1.128-.003l-3.615 3.615z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"url(#exyroFa)\" fill-rule=\"nonzero\" d=\"M15.927 1.793a6.356 6.356 0 016.148 0L32.92 7.825C34.826 8.886 36 10.845 36 12.968v12.064c0 2.122-1.175 4.083-3.08 5.144l-10.845 6.03a6.345 6.345 0 01-6.148 0l-10.846-6.03C3.175 29.116 2 27.155 2 25.032V12.968c0-2.124 1.175-4.082 3.081-5.143l10.846-6.032z\"/><path fill=\"#CDBFDB\" fill-rule=\"nonzero\" d=\"M5.49 9.148c-1.343.743-2.168 2.109-2.168 3.587v12.53c0 1.477.825 2.845 2.168 3.587l11.336 6.263a4.517 4.517 0 004.35 0l11.336-6.262c1.341-.743 2.166-2.11 2.166-3.588v-12.53c0-1.478-.824-2.844-2.166-3.587L21.177 2.883a4.527 4.527 0 00-4.352 0L5.49 9.148zm10.437-7.355a6.356 6.356 0 016.148 0L32.92 7.825C34.826 8.886 36 10.845 36 12.968v12.064c0 2.122-1.175 4.083-3.08 5.144l-10.845 6.03a6.345 6.345 0 01-6.148 0l-10.846-6.03C3.175 29.116 2 27.155 2 25.032V12.968c0-2.124 1.175-4.082 3.081-5.143l10.846-6.032z\"/><use fill=\"url(#exyroFb)\" xlink:href=\"#exyroFc\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "38",
      "height": "38",
      "viewBox": "0 0 38 38"
    }
  };
  _exports.default = _default;
});
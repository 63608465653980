define('ember-data-github/adapters/github-organization', ['exports', 'ember-data-github/adapters/github'], function (exports, _github) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _github.default.extend({
    buildURL(type, id, snapshot) {
      let builtURL = this._super(type, id, snapshot);
      builtURL = builtURL.replace('organizations', 'orgs');
      return builtURL;
    }
  });
});
define("ember-svg-jar/inlined/cc-amex-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><linearGradient id=\"bFTkAAa\" x1=\"50%\" x2=\"50%\" y1=\"0%\" y2=\"100%\"><stop offset=\"0%\" stop-color=\"#1782E1\"/><stop offset=\"100%\" stop-color=\"#016FD0\"/></linearGradient></defs><g fill=\"none\" fill-rule=\"evenodd\"><rect width=\"22\" height=\"16\" fill=\"url(#bFTkAAa)\" rx=\"3\"/><path fill=\"#000\" fill-opacity=\".101\" fill-rule=\"nonzero\" d=\"M2.994.75A2.247 2.247 0 00.75 3.003v9.994a2.25 2.25 0 002.244 2.253h16.012a2.247 2.247 0 002.244-2.253V3.003A2.25 2.25 0 0019.006.75H2.994zm0-.75h16.012A3 3 0 0122 3.003v9.994A2.997 2.997 0 0119.006 16H2.994A3 3 0 010 12.997V3.003A2.997 2.997 0 012.994 0z\"/><path fill=\"#FFF\" fill-rule=\"nonzero\" d=\"M19.404 13l-.567-.756-.576.756H13V8h.953l-.318-.788h-.845L12.475 8H9.8L12 3h2.258l.678 1.56V3h2.566l.404 1.127.39-1.127H19.4A1.6 1.6 0 0121 4.6v6.8c0 .282-.073.547-.201.777l-1.395-1.753 1.502-1.872h-1.133l-.91 1.172-.906-1.172h-1.18l1.523 1.895-1.487 1.849h1.162l.86-1.153.88 1.153h1.01a1.598 1.598 0 01-1.321.704zm.045-5h-1.155l.568.74.587-.74zm-5.809 4.296h3.055v-.813h-2.109v-.665h2.06v-.788h-2.06v-.64h2.109v-.838H13.64v3.744zm-1.024-8.68l-1.61 3.744h1.048l.298-.75h1.727l.295.75h1.072l-1.609-3.744h-1.22zm1.123 2.182h-1.055l.528-1.31.527 1.31zm1.818 1.562h.936V4.6l.995 2.759h.82L19.3 4.6V7.36h.936V3.616H18.76l-.846 2.42-.867-2.415-1.49-.005V7.36z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "22",
      "height": "16",
      "viewBox": "0 0 22 16"
    }
  };
  _exports.default = _default;
});
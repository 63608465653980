define("ember-svg-jar/inlined/dyno-free", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>dyno-free</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M8.39.169l5.72 2.953c.487.25.89.907.89 1.459v6.865c0 .548-.398 1.204-.89 1.456l-5.72 2.93c-.487.25-1.288.252-1.78 0l-5.72-2.93c-.487-.25-.89-.904-.89-1.456V4.581c0-.548.398-1.206.89-1.46L6.61.17c.487-.251 1.288-.254 1.78 0z\" fill=\"#F1EEF5\"/><path d=\"M8.39.169l5.72 2.953c.487.25.89.907.89 1.459v6.865c0 .548-.398 1.204-.89 1.456l-5.72 2.93c-.487.25-1.288.252-1.78 0l-5.72-2.93c-.487-.25-.89-.904-.89-1.456V4.581c0-.548.398-1.206.89-1.46L6.61.17c.487-.251 1.288-.254 1.78 0zm-.459.889c-.202-.105-.661-.104-.862 0L1.349 4.01C1.19 4.092 1 4.405 1 4.58v6.866c0 .175.191.487.346.566l5.72 2.93c.203.104.666.104.868 0l5.72-2.93c.156-.08.346-.391.346-.566V4.581c0-.176-.192-.49-.348-.57l-5.72-2.953z\" fill=\"#C1B1D3\"/><path fill=\"#A185C0\" d=\"M6 11h1.566V8.81h1.698V7.547H7.566v-1.2H10V5H6z\"/></g>",
    "attrs": {
      "width": "15",
      "height": "16",
      "viewBox": "0 0 15 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
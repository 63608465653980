define("@heroku/ember-hk-components/templates/components/hk-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "KCPeJBB8",
    "block": "[[[41,[33,1],[[[1,\"  \"],[8,[39,2],null,[[\"@name\",\"@size\",\"@iconClass\",\"@title\"],[\"loading-16\",16,\"fill-gray v-mid\",\"Loading\"]],null],[1,\"\\n\"]],[]],[[[41,[48,[30,1]],[[[1,\"    \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[2,[36,5]],[1,\"\\n\"]],[]]]],[]]]],[\"&default\"],false,[\"if\",\"isPending\",\"malibu-icon\",\"has-block\",\"yield\",\"text\"]]",
    "moduleName": "@heroku/ember-hk-components/templates/components/hk-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
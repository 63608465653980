define("ember-cp-validations/-private/options", ["exports", "@ember/object", "ember-cp-validations/utils/utils"], function (_exports, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    keys
  } = Object;
  const OPTION_KEYS = '__option_keys__';

  const OptionsObject = _object.default.extend({
    toObject() {
      return this[OPTION_KEYS].reduce((obj, key) => {
        obj[key] = (0, _object.get)(this, key);
        return obj;
      }, {});
    }

  });

  class Options {
    constructor(_ref) {
      let {
        model,
        attribute,
        options = {}
      } = _ref;
      const optionKeys = keys(options);
      const createParams = {
        [OPTION_KEYS]: optionKeys,
        model,
        attribute
      }; // If any of the options is a CP, we need to create a custom class for it

      if (optionKeys.some(key => (0, _utils.isDescriptor)(options[key]))) {
        return OptionsObject.extend(options).create(createParams);
      }

      return OptionsObject.create(createParams, options);
    }

  }

  _exports.default = Options;
});
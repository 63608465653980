define("ember-svg-jar/inlined/region-united-states", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><linearGradient id=\"hwwBgYb\" x1=\"0%\" y1=\"0%\" y2=\"100%\"><stop offset=\"0%\" stop-color=\"#F37A65\"/><stop offset=\"100%\" stop-color=\"#D64242\"/></linearGradient><linearGradient id=\"hwwBgYd\" x1=\"0%\" y1=\"0%\" y2=\"100%\"><stop offset=\"0%\" stop-color=\"#64B5F3\"/><stop offset=\"100%\" stop-color=\"#347EE2\"/></linearGradient><rect id=\"hwwBgYa\" width=\"20\" height=\"13\" rx=\"2\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><mask id=\"hwwBgYc\" fill=\"#fff\"><use xlink:href=\"#hwwBgYa\"/></mask><use fill=\"#FFF\" xlink:href=\"#hwwBgYa\"/><path fill=\"url(#hwwBgYb)\" d=\"M0 0h20v1H0V0zm0 2h20v1H0V2zm0 2h20v1H0V4zm0 2h20v1H0V6zm0 2h20v1H0V8zm0 2h20v1H0v-1zm0 2h20v1H0v-1z\" mask=\"url(#hwwBgYc)\"/><path fill=\"url(#hwwBgYd)\" d=\"M1.997 0A1.994 1.994 0 000 2.006v3.991A1 1 0 00.998 7h7.004C8.553 7 9 6.562 9 5.997V1.003A1 1 0 008.002 0H1.997z\"/><path fill=\"#FFF\" fill-opacity=\".705\" d=\"M1 1h1v1H1V1zm2 0h1v1H3V1zm2 0h1v1H5V1zM2 3h1v1H2V3zm2 0h1v1H4V3zm2 0h1v1H6V3zm1-2h1v1H7V1zM1 5h1v1H1V5zm2 0h1v1H3V5zm2 0h1v1H5V5zm2 0h1v1H7V5z\"/><path fill=\"#000\" fill-opacity=\".2\" fill-rule=\"nonzero\" d=\"M2.994.75A2.247 2.247 0 00.75 3.003v6.994a2.25 2.25 0 002.244 2.253h14.012a2.247 2.247 0 002.244-2.253V3.003A2.25 2.25 0 0017.006.75H2.994zm0-.75h14.012A3 3 0 0120 3.003v6.994A2.997 2.997 0 0117.006 13H2.994A3 3 0 010 9.997V3.003A2.997 2.997 0 012.994 0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "20",
      "height": "14",
      "viewBox": "0 0 20 14"
    }
  };
  _exports.default = _default;
});
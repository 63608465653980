define('ember-data-github/initializers/github-inflector-rules', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {
    const inflector = _emberInflector.default.inflector;
    inflector.uncountable('githubRepositoryContents');
  }

  exports.default = {
    name: 'github-inflector-rules',
    initialize
  };
});
define('ember-responsive/helpers/media', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    init() {
      this._super(...arguments);
      this.get('media').on('mediaChanged', () => {
        this.recompute();
      });
    },

    media: Ember.inject.service(),

    compute([prop]) {
      return Ember.get(this, `media.${prop}`);
    }
  });
});
define("hk-components/templates/components/hk-danger-message", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hcKeIiol",
    "block": "[[[10,0],[14,0,\"hk-danger-message-heading flex items-center mt1\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"failure-ring-16\",\"malibu-fill-gradient-red mh1 nudge-up--1\",16,\"Warning\"]],null],[1,\"\\n  \"],[10,1],[14,0,\"b\"],[12],[1,[34,1]],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"hk-danger-message-body ml4 mr2 mv1 pl1\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"malibu-icon\",\"heading\",\"yield\"]]",
    "moduleName": "hk-components/templates/components/hk-danger-message.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
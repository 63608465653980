define("ember-svg-jar/inlined/dyno-space-ps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>dyno-space-ps</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#E0E8F3\" d=\"M8.39.19l5.72 2.952c.487.251.89.907.89 1.459v6.866c0 .548-.398 1.203-.89 1.455l-5.72 2.93c-.487.25-1.288.253-1.78 0l-5.72-2.93C.403 12.673 0 12.02 0 11.467V4.6c0-.548.398-1.205.89-1.459L6.61.189c.487-.25 1.288-.253 1.78 0z\"/><path fill=\"#A5C6ED\" fill-rule=\"nonzero\" d=\"M8.39.19l5.72 2.952c.487.251.89.907.89 1.459v6.866c0 .548-.398 1.203-.89 1.455l-5.72 2.93c-.487.25-1.288.253-1.78 0l-5.72-2.93C.403 12.673 0 12.02 0 11.467V4.6c0-.548.398-1.205.89-1.459L6.61.189c.487-.25 1.288-.253 1.78 0zm-.459.888c-.202-.104-.661-.104-.862 0L1.349 4.03C1.19 4.112 1 4.426 1 4.6v6.866c0 .174.191.486.346.565l5.72 2.93c.203.105.666.104.868 0l5.72-2.93c.156-.08.346-.39.346-.565V4.6c0-.175-.192-.49-.348-.57l-5.72-2.953z\"/><path fill=\"#77ACEB\" d=\"M2.55 11V5.08h2.216c1.264 0 2.56.264 2.56 2.016s-1.4 2.016-2.56 2.016h-.632V11H2.55zm1.584-3.104h.688c.744 0 .944-.312.944-.76s-.2-.76-.944-.76h-.688v1.52zm6.264 3.216c1.824 0 2.488-.936 2.488-1.88 0-1.328-1.296-1.624-2.264-1.856-.584-.144-1.048-.288-1.048-.68 0-.28.232-.432.688-.432.512 0 1.088.344 1.416.744l1.072-.88c-.472-.576-1.224-1.16-2.48-1.16-1.344 0-2.256.664-2.256 1.872 0 1.344 1.288 1.592 2.256 1.84.584.144 1.056.288 1.056.664 0 .368-.368.512-.856.512-.616 0-1.2-.328-1.552-.896l-1.184.736c.504.808 1.312 1.416 2.664 1.416z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "17",
      "viewBox": "0 0 15 17"
    }
  };
  _exports.default = _default;
});
define('ember-data-github/adapters/github-pull', ['exports', 'ember-data-github/adapters/github'], function (exports, _github) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _github.default.extend({
    urlForQuery(query) {
      const repo = query.repo;
      delete query.repo;
      return `${this.get('host')}/repos/${repo}/pulls`;
    },

    urlForQueryRecord(query) {
      const repo = query.repo;
      const pullId = query.pullId;
      delete query.repo;
      delete query.pullId;

      return `${this.get('host')}/repos/${repo}/pulls/${pullId}`;
    }
  });
});
define("ember-sortable/utils/constant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.END_ACTIONS = _exports.ELEMENT_CLICK_ACTION = _exports.DRAG_ACTIONS = _exports.ANNOUNCEMENT_ACTION_TYPES = void 0;
  const DRAG_ACTIONS = ['mousemove', 'touchmove'];
  _exports.DRAG_ACTIONS = DRAG_ACTIONS;
  const ELEMENT_CLICK_ACTION = 'click';
  _exports.ELEMENT_CLICK_ACTION = ELEMENT_CLICK_ACTION;
  const END_ACTIONS = ['click', 'mouseup', 'touchend'];
  _exports.END_ACTIONS = END_ACTIONS;
  const ANNOUNCEMENT_ACTION_TYPES = {
    ACTIVATE: 'ACTIVATE',
    MOVE: 'MOVE',
    CONFIRM: 'CONFIRM',
    CANCEL: 'CANCEL'
  };
  _exports.ANNOUNCEMENT_ACTION_TYPES = ANNOUNCEMENT_ACTION_TYPES;
});
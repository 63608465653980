define("@heroku/ember-hk-components/components/hk-slide-panel/confirmable-action", ["exports", "@ember/component", "@heroku/ember-hk-components/templates/components/hk-slide-panel/confirmable-action", "@ember/object/computed"], function (_exports, _component, _confirmableAction, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _confirmableAction.default,
    'data-test-target': 'slide-panel-confirmable-action',
    primaryButtonType: 'danger-primary',
    onConfirm: () => {},
    onDismiss: () => {},
    onCancel: (0, _computed.alias)('onDismiss'),
    onClickOverlay: (0, _computed.reads)('onDismiss')
  });

  _exports.default = _default;
});
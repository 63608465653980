define('ember-data-github/adapters/github-release', ['exports', 'ember-data-github/adapters/github'], function (exports, _github) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _github.default.extend({
    urlForQuery(query) {
      const repo = query.repo;
      delete query.repo;
      return `${this.get('host')}/repos/${repo}/releases`;
    },

    urlForQueryRecord(query) {
      const repo = query.repo;
      const releaseId = query.releaseId;
      delete query.repo;
      delete query.releaseId;

      return `${this.get('host')}/repos/${repo}/releases/${releaseId}`;
    }
  });
});
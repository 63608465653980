define('ember-data-github/models/github-repository', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    description: (0, _attr.default)('string'),
    fullName: (0, _attr.default)('string'),
    homepage: (0, _attr.default)('string'),
    language: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),

    forks: (0, _attr.default)('number'),
    forksCount: (0, _attr.default)('number'),
    openIssues: (0, _attr.default)('number'),
    openIssuesCount: (0, _attr.default)('number'),
    size: (0, _attr.default)('number'),
    stargazersCount: (0, _attr.default)('number'),
    watchers: (0, _attr.default)('number'),
    watchersCount: (0, _attr.default)('number'),

    fork: (0, _attr.default)('boolean'),
    hasDownloads: (0, _attr.default)('boolean'),
    hasIssues: (0, _attr.default)('boolean'),
    hasPages: (0, _attr.default)('boolean'),
    hasProjects: (0, _attr.default)('boolean'),
    hasWiki: (0, _attr.default)('boolean'),
    private: (0, _attr.default)('boolean'),

    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    pushedAt: (0, _attr.default)('date'),

    cloneUrl: (0, _attr.default)('string'),
    gitUrl: (0, _attr.default)('string'),
    sshUrl: (0, _attr.default)('string'),
    svnUrl: (0, _attr.default)('string'),

    // Urls
    archiveUrl: (0, _attr.default)('string'),
    assigneesUrl: (0, _attr.default)('string'),
    blobsUrl: (0, _attr.default)('string'),
    branchesUrl: (0, _attr.default)('string'),
    collaboratorsUrl: (0, _attr.default)('string'),
    commentsUrl: (0, _attr.default)('string'),
    commitsUrl: (0, _attr.default)('string'),
    compareUrl: (0, _attr.default)('string'),
    contentsUrl: (0, _attr.default)('string'),
    contributorsUrl: (0, _attr.default)('string'),
    deploymentsUrl: (0, _attr.default)('string'),
    downloadsUrl: (0, _attr.default)('string'),
    eventsUrl: (0, _attr.default)('string'),
    forksUrl: (0, _attr.default)('string'),
    gitCommitsUrl: (0, _attr.default)('string'),
    gitRefsUrl: (0, _attr.default)('string'),
    gitTagsUrl: (0, _attr.default)('string'),
    hooksUrl: (0, _attr.default)('string'),
    htmlUrl: (0, _attr.default)('string'),
    issueCommentUrl: (0, _attr.default)('string'),
    issueEventsUrl: (0, _attr.default)('string'),
    issuesUrl: (0, _attr.default)('string'),
    keysUrl: (0, _attr.default)('string'),
    labelsUrl: (0, _attr.default)('string'),
    mergesUrl: (0, _attr.default)('string'),
    milestonesUrl: (0, _attr.default)('string'),
    notificationsUrl: (0, _attr.default)('string'),
    pullsUrl: (0, _attr.default)('string'),
    releasesUrl: (0, _attr.default)('string'),
    statusesUrl: (0, _attr.default)('string'),
    subscribersUrl: (0, _attr.default)('string'),
    subscriptionUrl: (0, _attr.default)('string'),
    tagsUrl: (0, _attr.default)('string'),
    teamsUrl: (0, _attr.default)('string'),
    treesUrl: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),

    // Embedded Objects
    branches: (0, _relationships.hasMany)('github-branch'),
    defaultBranch: (0, _relationships.belongsTo)('github-branch', { inverse: null }),
    license: (0, _attr.default)(), // TODO: Make into a real model
    owner: (0, _relationships.belongsTo)('github-user'),
    pulls: (0, _relationships.hasMany)('github-pull'),
    releases: (0, _relationships.hasMany)('github-release')
  });
});
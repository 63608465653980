define("ember-modal-dialog/components/liquid-dialog", ["exports", "@ember-decorators/component", "ember-modal-dialog/components/basic-dialog", "ember-modal-dialog/templates/components/liquid-dialog"], function (_exports, _component, _basicDialog, _liquidDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let LiquidDialog = (_dec = (0, _component.layout)(_liquidDialog.default), _dec(_class = class LiquidDialog extends _basicDialog.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "hasOverlay", true);

      _defineProperty(this, "variantWrapperClass", 'emd-animatable');
    }

    init() {
      super.init(...arguments);
      this.containerClassNames?.push('liquid-dialog');
    }

  }) || _class);
  _exports.default = LiquidDialog;
});
define("ember-cli-flash/flash/object", ["exports", "@ember/object/evented", "@ember/object", "@ember/runloop", "ember-cli-flash/utils/computed"], function (_exports, _evented, _object, _runloop, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend(_evented.default, {
    exitTimer: null,
    exiting: false,
    isExitable: true,
    initializedTime: null,
    _guid: (0, _computed.guidFor)('message').readOnly(),

    init() {
      this._super(...arguments);

      if ((0, _object.get)(this, 'sticky')) {
        return;
      }

      this.timerTask();

      this._setInitializedTime();
    },

    destroyMessage() {
      this._cancelTimer();

      let exitTaskInstance = (0, _object.get)(this, 'exitTaskInstance');

      if (exitTaskInstance) {
        (0, _runloop.cancel)(exitTaskInstance);

        this._teardown();
      } else {
        this.exitTimerTask();
      }
    },

    exitMessage() {
      if (!(0, _object.get)(this, 'isExitable')) {
        return;
      }

      this.exitTimerTask();
      this.trigger('didExitMessage');
    },

    willDestroy() {
      const onDestroy = (0, _object.get)(this, 'onDestroy');

      if (onDestroy) {
        onDestroy();
      }

      this._cancelTimer();

      this._cancelTimer('exitTaskInstance');

      this._super(...arguments);
    },

    preventExit() {
      (0, _object.set)(this, 'isExitable', false);
    },

    allowExit() {
      (0, _object.set)(this, 'isExitable', true);

      this._checkIfShouldExit();
    },

    timerTask() {
      let timeout = (0, _object.get)(this, 'timeout');

      if (!timeout) {
        return;
      }

      let timerTaskInstance = (0, _runloop.later)(() => {
        this.exitMessage();
      }, timeout);
      (0, _object.set)(this, 'timerTaskInstance', timerTaskInstance);
    },

    exitTimerTask() {
      if ((0, _object.get)(this, 'isDestroyed')) {
        return;
      }

      (0, _object.set)(this, 'exiting', true);
      let extendedTimeout = (0, _object.get)(this, 'extendedTimeout');

      if (extendedTimeout) {
        let exitTaskInstance = (0, _runloop.later)(() => {
          this._teardown();
        }, extendedTimeout);
        (0, _object.set)(this, 'exitTaskInstance', exitTaskInstance);
      } else {
        this._teardown();
      }
    },

    // private
    _setInitializedTime() {
      let currentTime = new Date().getTime();
      (0, _object.set)(this, 'initializedTime', currentTime);
    },

    _getElapsedTime() {
      let currentTime = new Date().getTime();
      let initializedTime = (0, _object.get)(this, 'initializedTime');
      return currentTime - initializedTime;
    },

    _cancelTimer() {
      let taskName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'timerTaskInstance';
      let taskInstance = (0, _object.get)(this, taskName);

      if (taskInstance) {
        (0, _runloop.cancel)(taskInstance);
      }
    },

    _checkIfShouldExit() {
      if (this._getElapsedTime() >= (0, _object.get)(this, 'timeout') && !(0, _object.get)(this, 'sticky')) {
        this._cancelTimer();

        this.exitMessage();
      }
    },

    _teardown() {
      const queue = (0, _object.get)(this, 'flashService.queue');

      if (queue) {
        queue.removeObject(this);
      }

      this.destroy();
      this.trigger('didDestroyMessage');
    }

  });

  _exports.default = _default;
});
define("ember-svg-jar/inlined/github-status-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><linearGradient id=\"jNUBKla\" x1=\"2.617%\" y1=\"2.617%\" y2=\"100%\"><stop offset=\"0%\" stop-color=\"#FAA049\"/><stop offset=\"100%\" stop-color=\"#E56000\"/></linearGradient></defs><path fill=\"url(#jNUBKla)\" fill-rule=\"evenodd\" d=\"M4.973 9.6l4.523 4.524c-.843 1.078-1.343 2.4-1.343 3.97 0 6.345 3.969 7.765 7.744 8.183-.485.415-.925 1.144-1.08 2.214-.968.424-3.43 1.155-4.947-1.375 0 0-.898-1.59-2.604-1.707 0 0-1.66-.022-.117 1.006 0 0 1.115.51 1.888 2.423 0 0 .998 3.22 5.726 2.22.009 1.381.023 2.422.023 2.816 0 .439-.31.95-1.16.798C6.87 32.482 2 26.275 2 18.96c0-3.473 1.098-6.697 2.973-9.36zm5.14-4.76a17.253 17.253 0 019.18-2.438C28.546 2.556 36 9.91 36 18.96c0 3.33-1.009 6.429-2.744 9.024l-4.764-4.764c.836-1.19 1.352-2.836 1.352-5.126 0-1.809-.658-3.287-1.747-4.445.174-.419.757-2.102-.17-4.381 0 0-1.425-.446-4.67 1.696a16.722 16.722 0 00-4.256-.557 17.16 17.16 0 00-3.04.282L10.112 4.84zm18.123 28.023a17.133 17.133 0 01-3.851 1.807c-.864.161-1.17-.353-1.17-.796 0-.543.02-2.33.02-4.543 0-.688-.107-1.27-.28-1.749l5.28 5.281zM2.908 5.414a2 2 0 012.828-2.828l29.699 29.698a2 2 0 01-2.829 2.829L2.908 5.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "38",
      "height": "38",
      "viewBox": "0 0 38 38"
    }
  };
  _exports.default = _default;
});
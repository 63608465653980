define("ember-modifier/-private/class/modifier-manager", ["exports", "@ember/modifier", "@ember/object", "@ember/destroyable"], function (_exports, _modifier, _object, _destroyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function destroyModifier(modifier) {
    modifier.willRemove();
    modifier.willDestroy();
  }

  class ClassBasedModifierManager {
    constructor(owner) {
      this.owner = owner;

      _defineProperty(this, "capabilities", (0, _modifier.capabilities)('3.13'));
    }

    createModifier(factory, args) {
      const Modifier = factory.class;
      const modifier = new Modifier(this.owner, args);
      (0, _destroyable.registerDestructor)(modifier, destroyModifier);
      return modifier;
    }

    installModifier(instance, element) {
      instance.element = element;
      instance.didReceiveArguments();
      instance.didInstall();
    }

    updateModifier(instance, args) {
      // TODO: this should be an args proxy
      (0, _object.set)(instance, 'args', args);
      instance.didUpdateArguments();
      instance.didReceiveArguments();
    }

    destroyModifier(instance) {
      (0, _destroyable.destroy)(instance);
    }

  }

  _exports.default = ClassBasedModifierManager;
});
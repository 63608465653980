define('ember-data-github/models/github-commit', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    sha: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    parents: (0, _attr.default)(),
    commit: (0, _attr.default)(),
    author: (0, _attr.default)(),
    committer: (0, _attr.default)()
  });
});
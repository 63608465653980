define("@heroku/ember-malibu-icon/components/malibu-icon", ["exports", "@ember/component", "@heroku/ember-malibu-icon/templates/components/malibu-icon", "@ember/object", "@ember/object/internals", "@ember/debug", "@heroku/ember-malibu-icon/-private/utils", "@ember/string"], function (_exports, _component, _malibuIcon, _object, _internals, _debug, _utils, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let MalibuIcon = (_dec = (0, _object.computed)('iconWidth', 'iconHeight'), _dec2 = (0, _object.computed)('width', 'size'), _dec3 = (0, _object.computed)('height', 'size'), (_class = class _class extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "layout", _malibuIcon.default);

      _defineProperty(this, "labelID", `malibu-icon-${(0, _internals.guidFor)(this)}`);

      _defineProperty(this, "svgID", `malibu-icon-svg-${(0, _internals.guidFor)(this)}`);

      _defineProperty(this, "supportsDataTestProperties", true);

      _defineProperty(this, "tagName", '');
    }

    get style() {
      let {
        iconHeight,
        iconWidth
      } = this;
      iconHeight = (0, _utils.requireNumeric)(iconHeight);
      iconWidth = (0, _utils.requireNumeric)(iconWidth);
      return (0, _string.htmlSafe)(`height: ${iconHeight}px; width: ${iconWidth}px;`);
    }

    get iconWidth() {
      return this.width || this.size;
    }

    get iconHeight() {
      return this.height || this.size;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "style", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "style"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "iconWidth", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "iconWidth"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "iconHeight", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "iconHeight"), _class.prototype)), _class));

  if (false
  /* DEBUG */
  ) {
    MalibuIcon = class extends MalibuIcon {
      didRender() {
        const svg = document.querySelector(`#${this.svgID}`);
        const ariaHidden = svg.getAttribute('aria-hidden');
        (false && (0, _debug.warn)(`Please set @title for <MalibuIcon @name="${this.name}" /> ` + 'or set aria-hidden="true" if you believe the icon does not ' + 'need a title and is solely decorative.', this.title || ariaHidden === 'true', {
          id: 'ember-malibu-icon.missing-title',
          url: 'https://github.com/heroku/ember-malibu-icon#accessibility'
        }));
        (false && (0, _debug.warn)(`<MalibuIcon @name="${this.name}" /> does not allow setting both @title and aria-hidden="true", ` + 'as aria-hidden="true" would hide the text from screenreaders/accessibility tools. ' + 'Please remove the @title argument if the icon is used in a decorative manner, or remove the `aria-hidden` attribute.', !(this.title && ariaHidden === 'true'), {
          id: 'ember-malibu-icon.mismatched-title-and-aria-hidden',
          url: 'https://github.com/heroku/ember-malibu-icon#accessibility'
        }));
        super.didRender(...arguments);
      }

    };
  }

  var _default = MalibuIcon;
  _exports.default = _default;
});
define("@heroku/ember-hk-components/templates/components/hk-search-input", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "oZBffz//",
    "block": "[[[8,[39,0],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"search-16\",16,\"fill-gray absolute ml2 mt1 nudge-down--3\"]],null],[1,\"\\n\"],[1,[28,[35,1],null,[[\"id\",\"value\",\"placeholder\",\"class\",\"autocapitalize\",\"autocorrect\",\"autocomplete\",\"spellcheck\"],[[33,2],[33,3],[33,4],\"hk-search-input w-100\",\"off\",\"off\",\"off\",\"false\"]]]],[1,\"\\n\"]],[],false,[\"malibu-icon\",\"input\",\"inputId\",\"value\",\"placeholder\"]]",
    "moduleName": "@heroku/ember-hk-components/templates/components/hk-search-input.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
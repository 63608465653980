define('ember-data-github/models/github-organization', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    login: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    avatarUrl: (0, _attr.default)('string'),

    members: (0, _relationships.hasMany)('github-member'),
    repositories: (0, _relationships.hasMany)('github-repository'),

    githubUsers: Ember.computed('members.[]', function () {
      Ember.deprecate('The githubUsers property on the github-organization model has been deprecated.  Please use the members property.', false, { id: 'ember-data-github.deprecated-model-props', until: '1.0.0' });
      return this.get('members');
    }),
    githubRepositories: Ember.computed('repositories.[]', function () {
      Ember.deprecate('The githubRepositories property on the github-organization model has been deprecated.  Please use the repositories property.', false, { id: 'ember-data-github.deprecated-model-props', until: '1.0.0' });
      return this.get('repositories');
    })
  });
});